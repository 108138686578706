


















import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

import WidgetCollapse from '~/types/widgetCollapse'

@Component({
  name: 'common-collapse',
})
export default class Collapse extends Vue {
  @Prop() public collapseState: number
  @Prop({ default: 500 })
  public height: number

  @Prop() public contentElement: HTMLElement
  @Prop() public calcTargetElement: HTMLElement
  @Prop() public disableShowLess: boolean

  private internalCollapseState = WidgetCollapse.Minimize
  private contentHeight = this.height

  get representedCollapseState() {
    if (this.disableShowLess) {
      if (this.internalCollapseState === WidgetCollapse.Expand) {
        return WidgetCollapse.Full
      }
      return this.internalCollapseState
    }
    return this.internalCollapseState
  }

  get isShowContent() {
    return this.representedCollapseState !== WidgetCollapse.Minimize
  }

  get isShowCollapseBtn() {
    return this.representedCollapseState !== WidgetCollapse.Minimize
  }

  // Only show on desktop
  get isShowExpandBtn() {
    return !this.disableShowLess && this.representedCollapseState !== WidgetCollapse.Expand
  }

  get isShowExpandAllBtn() {
    return (
      (this.disableShowLess || this.needToScroll) &&
      this.representedCollapseState !== WidgetCollapse.Full
    )
  }

  get conditionalExpandString() {
    return this.needToScroll ? 'SHOW LESS' : 'SHOW ALL'
  }

  get needToScroll() {
    return this.contentHeight > this.height
  }

  public handleCollapse() {
    this.internalCollapseState = WidgetCollapse.Minimize
    this.$emit('update', this.internalCollapseState)
  }

  public handleExpand() {
    this.internalCollapseState = WidgetCollapse.Expand
    this.$emit('update', this.internalCollapseState)
  }

  public handleExpandMore() {
    this.internalCollapseState = WidgetCollapse.Full
    this.$emit('update', this.internalCollapseState)
  }

  public recalculateHeight() {
    this.calculateContentHeight()
  }

  @Watch('collapseState')
  public onValueChanged() {
    this.internalCollapseState = this.collapseState
    this.applyStyleInEle()
  }

  public mounted() {
    this.internalCollapseState = this.collapseState
    this.calculateContentHeight()
  }

  private calculateContentHeight() {
    if (this.calcTargetElement) {
      const rect = this.calcTargetElement.getBoundingClientRect()
      this.contentHeight = rect.height
    }
    this.applyStyleInEle()
  }

  private applyStyleInEle() {
    let height = '0px'
    switch (this.representedCollapseState) {
      case WidgetCollapse.Minimize:
        height = '0px'
        break
      case WidgetCollapse.Expand:
        height = Math.min(this.height, this.contentHeight) + 'px'
        break
      case WidgetCollapse.Full:
        height = 'auto'
        break
    }
    this.contentElement && (this.contentElement.style.height = height)
  }
}
