





import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'common-custom-button-group',
})
export default class CustomButtonGroup extends Vue {
  public click: boolean = false
}
