














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import dashboardIcon from '~/assets/dashboard-icon.png'

@Component({
  name: 'common-drawer-menu',
})
export default class DrawerMenu extends Vue {
  @Prop({ default: false })
  public drawer: boolean

  public dashboardIcon = dashboardIcon
  public toggle: boolean = false

  get groups() {
    return this.$store.myGetters['accounts/group/all']
  }

  @Watch('drawer')
  public drawerToggle(to: boolean) {
    this.toggle = this.drawer
  }

  @Watch('toggle')
  public drawerChange(to: boolean) {
    this.$emit('update:drawer', to)
  }

  public isActive(path: string) {
    return this.$route.name === path
  }
}
