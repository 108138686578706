












import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'common-bread-crumb',
})
export default class Breadcrumb extends Vue {
  @Prop()
  public items: any[]

  get filteredItems() {
    if (this.items) {
      return this.isMobile ? [this.items[this.items.length - 1]] : this.items
    }
    return []
  }

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  @Watch('$route')
  public onRouteChange() {
    this.items.forEach(item => {
      if (item.to) {
        item.to = { ...item.to, params: this.$route.params }
      }
    })
  }
}
