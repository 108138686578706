











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'common-data-iterator',
})
export default class DataIterator extends Vue {
  @Prop({ default: [] })
  public items: any[]

  get props() {
    return {
      items: this.items,
    }
  }

  get itemScopedSlot() {
    return this.$scopedSlots.item
  }
}
