





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'common-custom-chip',
})
export default class CustomChip extends Vue {
  @Prop() public disabled: boolean

  @Prop({
    default: '#bbbbbb',
  })
  public bgColor: string

  @Prop({
    default: '#ddd',
  })
  public disabledBgColor: string

  @Prop({
    default: '#fff',
  })
  public textColor: string

  get chipStyle() {
    return {
      color: this.textColor,
      'background-color': this.disabled ? this.disabledBgColor : this.bgColor,
    }
  }
}
